import React, { memo, useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useRoutes } from "../../../hooks/useRoutes"

export const withAccountInvalid = Component =>
  memo(({ name = "AccountInvalid", login, page }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { linkResolver } = useRoutes()

    const link = linkResolver(login, `login`)
    const content = sanityContent(page?.content)

    Component.displayName = name
    return useMemo(() => <Component content={content} link={link} page={page} />, [content, link, page])
  })
