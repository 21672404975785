import React from "react"
import { graphql } from "gatsby"

import { AccountInvalid as Page } from "../../components/Account/Invalid/AccountInvalid"

export const query = graphql`
  query {
    page: sanityPageAccountInvalidToken {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      additionalContinue
    }
    login: sanityPageAccountLogin {
      title
    }
    template: sanityTemplateAccount {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
