import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountInvalid } from "./withAccountInvalid"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { Icon } from "../../Icon/Icon"
import { StyledContainer } from "../../Styled/Container"
import { StyledLink } from "../../Styled/Button"
import { StyledRow } from "../../Styled/Row"
import { Page, Title, Content, Link } from "./styledAccountInvalid"

export const AccountInvalid = withAccountInvalid(
  ({ content, link, page }): JSX.Element => (
    <Page>
      <Breadcrumbs background={`orange-pastel`} colour={`dark`} page={page} />
      <StyledContainer width={`md`}>
        <StyledRow justify={`center`} withSpacing>
          <Icon colour={`red`} name={`cross`} width={96} />
        </StyledRow>
        <Title as={`h1`} align={`center`} withSpacing>
          {page?.title}
        </Title>
        <Content align={`center`} colour={`grey-darker`} withSpacing={`xl`}>
          {content}
        </Content>
        <StyledRow justify={`center`}>
          <Link>
            <StyledLink as={GatsbyLink} colour={`orange-dark`} title={link?.title} to={link?.url}>
              {page?.additionalContinue}
            </StyledLink>
          </Link>
        </StyledRow>
      </StyledContainer>
    </Page>
  )
)
